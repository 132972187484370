@import url("https://fonts.googleapis.com/css2?family=Exo+2");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, #a60a0c, #4f7492 10rem);
  background-repeat: no-repeat;
}

/* breakpoints: {
  values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1500,
      xxl: 1800 */

.d4logo{
  height: 2rem;
  @media (min-width: 600px) {
    height: 4rem;
  }
  @media (min-width: 900px) {
    height: 4rem;
  }
  @media (min-width: 1200px) {
    height: 6rem;
  }
  @media (min-width: 1500px) {
    height: 6rem;
  }
  @media (min-width: 1800px) {
    height: 7rem;
  }
}

.pwlogo{
  height: 4rem;
  margin-top: .5rem;
  @media (min-width: 600px) {
    height: 5rem;
    margin-top: -1rem;
  }
  @media (min-width: 900px) {
    height: 6rem;
  }
  @media (min-width: 1200px) {
    height: 7rem;
    margin-top: -2rem;
  }
  @media (min-width: 1500px) {
    height: 8rem;
    margin-top: -2rem;
  }
  @media (min-width: 1800px) {
    height: 9rem;
    margin-top: -2.5rem;
  }
}


.aboutImage{
  border-radius: 1rem;
  max-width: 100%;
  
}

.homeImage{
  width: 100%;
  padding: 1rem;
  border-radius: 3rem;
  @media only screen and (max-height: 1100px) {
    height: 60vh;
    width: auto;
    margin: auto;
  }
}